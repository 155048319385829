var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row books"
  }, [_c('div', {
    staticClass: "col-md-12 mt-2"
  }, [_c('div', {
    staticClass: "card p-2"
  }, [_c('h4', {
    staticClass: "h3 text-center"
  }, [_vm._v("Premium Sales")]), _c('b-alert', {
    attrs: {
      "show": ""
    }
  }, [_vm._v(" Laporan penjualan berdasarkan Buffet/Premium Offers. ( Exclude Payment Free, Wallet, Gramedia.com ). Laporan di group berdasarkan Offers Name. ")]), _vm.isError ? _c('b-row', {
    staticClass: "justify-content-center mt-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "10"
    }
  }, [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": "",
      "dismissible": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")])], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('span', {
    staticClass: "col-sm-2"
  }, [_vm._v("Select Range")]), _c('div', {
    staticClass: "col-md-8"
  }, [_c('v-date-picker', {
    attrs: {
      "mode": "date",
      "masks": _vm.masks,
      "max-date": new Date(),
      "is-range": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var inputValue = _ref.inputValue,
          inputEvents = _ref.inputEvents,
          isDragging = _ref.isDragging;
        return [_c('div', {
          staticClass: "rows"
        }, [_c('input', _vm._g({
          staticClass: "flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full p-1",
          class: isDragging ? 'text-gray-600' : 'text-gray-900',
          attrs: {
            "max-date": new Date()
          },
          domProps: {
            "value": inputValue.start
          }
        }, inputEvents.start)), _c('span', {
          staticClass: "p-2"
        }, [_vm._v("to")]), _c('input', _vm._g({
          staticClass: "flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full p-1",
          class: isDragging ? 'text-gray-600' : 'text-gray-900',
          attrs: {
            "max-date": new Date()
          },
          domProps: {
            "value": inputValue.end
          }
        }, inputEvents.end))])];
      }
    }]),
    model: {
      value: _vm.range,
      callback: function ($$v) {
        _vm.range = $$v;
      },
      expression: "range"
    }
  })], 1), _c('div', {
    staticClass: "col-md-2"
  }, [_c('button', {
    staticClass: "btn btn-block btn-primary",
    on: {
      "click": _vm.actionSearch
    }
  }, [_c('i', {
    staticClass: "fa fa-search"
  })])])]), _c('div', {
    staticClass: "row justify-content-end"
  }, [_c('div', {
    staticClass: "col-md-2 mb-1"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.curs,
      expression: "curs"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "14000"
    },
    domProps: {
      "value": _vm.curs
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.curs = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "col-md-1 mb-1"
  }, [_c('button', {
    staticClass: "btn btn-block btn-outline-danger",
    on: {
      "click": _vm.actionCreateTablePDF
    }
  }, [_c('i', {
    staticClass: "fa fa-file-pdf-o"
  })])]), _c('div', {
    staticClass: "col-md-1 mb-1"
  }, [_c('button', {
    staticClass: "btn btn-block btn-outline-success",
    on: {
      "click": _vm.actionDownloadRecap
    }
  }, [_c('i', {
    staticClass: "fa fa-file-excel-o",
    attrs: {
      "aria-hidden": "true"
    }
  })])]), _c('div', {
    staticClass: "col-md-2"
  }, [_c('label', {
    staticClass: "col-sm-auto",
    attrs: {
      "for": ""
    }
  }, [_vm._v("Count ")]), _c('strong', {
    staticClass: "col-sm-auto"
  }, [_vm._v(" " + _vm._s(_vm.formatPriceBasic(_vm.totalRows)) + " ")])])]), _c('table', {
    staticClass: "table table-striped table-bordered table-sm table-responsive-sm mt-4"
  }, [_vm._m(0), !_vm.isLoading ? _c('tbody', [_vm._l(_vm.buffets, function (item, index) {
    return [_c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(++index))]), _c('td', [_vm._v(_vm._s(item.name))]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(item.count))]), item.currency_code == 'IDR' ? _c('td', {
      staticClass: "text-right"
    }, [_vm._v(" " + _vm._s(_vm.formatPrice(item.amount, item.currency_code)) + " ")]) : _c('td'), item.currency_code == 'USD' ? _c('td', {
      staticClass: "text-right"
    }, [_vm._v(" " + _vm._s(_vm.formatPrice(item.amount, item.currency_code)) + " ")]) : _c('td'), item.currency_code == 'PTS' ? _c('td', {
      staticClass: "text-right"
    }, [_vm._v(" " + _vm._s(_vm.formatPrice(item.amount, item.currency_code)) + " ")]) : _c('td')])];
  })], 2) : _c('tbody', [[_c('tr', [_c('td', {
    attrs: {
      "colspan": "6"
    }
  }, [_c('div', {
    staticClass: "text-center text-danger my-2"
  }, [_c('b-spinner', {
    staticClass: "align-middle"
  }), _c('strong', [_vm._v(" Loading...")])], 1)])])]], 2), _c('tfoot', [_c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v("Total")]), _c('td', {
    staticClass: "text-center"
  }, [_vm._v(" " + _vm._s(_vm.total) + " ")]), _c('td', {
    staticClass: "text-right"
  }, [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.totalAmount, "IDR")) + " ")]), _c('td', {
    staticClass: "text-right"
  }, [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.totalAmountD, "USD")) + " ( " + _vm._s(_vm.formatPrice(_vm.totalAmountD * _vm.curs, "IDR")) + " ) ")]), _c('td', {
    staticClass: "text-right"
  }, [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.totalAmountPTS, "IDR")) + " ")])]), _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "4"
    }
  }, [_vm._v("Total")]), _c('td', {
    staticClass: "text-right",
    attrs: {
      "colspan": "2"
    }
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.totalAmount + _vm.totalAmountD * _vm.curs + _vm.totalAmountPTS, "IDR")) + " ")])])])])])])], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Offers Name")]), _c('th', [_vm._v("Count")]), _c('th', [_vm._v("Price IDR")]), _c('th', [_vm._v("Price USD")]), _c('th', [_vm._v("Price PTN")])])]);
}]

export { render, staticRenderFns }