<template>
  <div class="row books">
    <div class="col-md-12 mt-2">
      <div class="card p-2">
        <h4 class="h3 text-center">Premium Sales</h4>
        <b-alert show>
          Laporan penjualan berdasarkan Buffet/Premium Offers. ( Exclude Payment Free, Wallet, Gramedia.com ). Laporan di group berdasarkan Offers Name.

        </b-alert>

        <b-row v-if="isError" class="justify-content-center mt-2">
          <b-col lg="10">
            <b-alert variant="danger" show dismissible>
              {{ errorMessage }}
            </b-alert>
          </b-col>
        </b-row>
        <div class="card-body">
          <div class="form-group row">
            <span class="col-sm-2">Select Range</span>
            <div class="col-md-8">
              <v-date-picker
                v-model="range"
                mode="date"
                :masks="masks"
                :max-date="new Date()"
                is-range
              >
                <template v-slot="{ inputValue, inputEvents, isDragging }">
                  <div class="rows">
                    <input
                      class="flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full p-1"
                      :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                      :value="inputValue.start"
                      v-on="inputEvents.start"
                      :max-date="new Date()"
                    />

                    <span class="p-2">to</span>

                    <input
                      class="flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full p-1"
                      :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                      :value="inputValue.end"
                      v-on="inputEvents.end"
                      :max-date="new Date()"
                    />
                  </div>
                </template>
              </v-date-picker>
            </div>
            <div class="col-md-2">
              <button class="btn btn-block btn-primary" @click="actionSearch">
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
          <div class="row justify-content-end">
            <div class="col-md-2 mb-1">
              <input
                type="text"
                v-model="curs"
                class="form-control"
                placeholder="14000"
              />
            </div>
            <div class="col-md-1 mb-1">
              <button
                class="btn btn-block btn-outline-danger"
                @click="actionCreateTablePDF"
              >
                <i class="fa fa-file-pdf-o"></i>
              </button>
            </div>
            <div class="col-md-1 mb-1">
              <button
                class="btn btn-block btn-outline-success"
                @click="actionDownloadRecap"
              >
                <i class="fa fa-file-excel-o" aria-hidden="true"></i>
              </button>
            </div>
            <div class="col-md-2">
              <label for="" class="col-sm-auto">Count </label>
              <strong class="col-sm-auto">
                {{ formatPriceBasic(totalRows) }}
              </strong>
            </div>
          </div>
          <table
            class="table table-striped table-bordered table-sm table-responsive-sm mt-4"
          >
            <thead>
              <tr>
                <th>No</th>
                <th>Offers Name</th>
                <th>Count</th>
                <th>Price IDR</th>
                <th>Price USD</th>
                <th>Price PTN</th>
              </tr>
            </thead>
            <tbody v-if="!isLoading">
              <template v-for="(item, index) in buffets">
                <tr :key="index">
                  <td>{{ ++index }}</td>
                  <td>{{ item.name }}</td>
                  <td class="text-center">{{ item.count }}</td>
                  <td v-if="item.currency_code == 'IDR'" class="text-right">
                    {{ formatPrice(item.amount, item.currency_code) }}
                  </td>
                  <td v-else></td>
                  <td v-if="item.currency_code == 'USD'" class="text-right">
                    {{ formatPrice(item.amount, item.currency_code) }}
                  </td>
                  <td v-else></td>
                  <td v-if="item.currency_code == 'PTS'" class="text-right">
                    {{ formatPrice(item.amount, item.currency_code) }}
                  </td>
                  <td v-else></td>
                </tr>
              </template>
            </tbody>
            <tbody v-else>
              <template>
                <tr>
                  <td colspan="6">
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong> Loading...</strong>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="2" class="text-center">Total</td>
                <td class="text-center">
                  {{ total }}
                </td>
                <td class="text-right">
                  {{ formatPrice(totalAmount, "IDR") }}
                </td>
                <td class="text-right">
                  {{ formatPrice(totalAmountD, "USD") }}
                  ( {{ formatPrice(totalAmountD * curs, "IDR") }} )
                </td>
                <td class="text-right">
                  {{ formatPrice(totalAmountPTS, "IDR") }}
                </td>
              </tr>
              <tr>
                <td colspan="4" class="text-center">Total</td>
                <td colspan="2" class="text-right">
                  <strong>
                    {{
                      formatPrice(
                        totalAmount + totalAmountD * curs + totalAmountPTS,
                        "IDR"
                      )
                    }}
                  </strong>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import xlsx from "xlsx";
import { mapActions,mapState} from "vuex";
import PDFReports from "../../plugins/PDFReports"
export default {
  name: "BuffetsReportSummary",
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      totalRows: 0,
      totalAmount: 0,
      totalAmountD: 0,
      totalAmountPTS: 0,
      curs: 14200,
      buffets: [],
      total:0,
      Dates: {
        from: moment().format("YYYY,MM,DD"),
        to: moment().format("YYYY,MM,DD"),
      },
      range: {
        start: moment().format("YYYY,MM,DD"),
        end: moment().format("YYYY,MM,DD"),
      },
      masks: {
        input: "YYYY-MM-DD",
      },
    };
  },
  watch: {
    currentPage: function() {
      this.actionSearch();
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.buffets.isLoading,
      isError: (state) => state.buffets.isError,
      errorMessage: (state)=> state.buffets.errorMessage
    }),
  },
  methods: {
    ...mapActions("buffets", ["fetchReport", "fetchReportBuffets"]),
    formatPrice(value, currency) {
      if (value) {
        var formatter = null;
        if (currency == "USD") {
          formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: currency,
          });
        } else {
          formatter = new Intl.NumberFormat("en-ID", {
            style: "currency",
            currency: currency,
          });
        }

        return formatter.format(value);
      }

      return 0;
    },
    formatDate(tgl) {
      //return moment(tgl).format("DD MMM YY hh:mm");
      return new Date(tgl).toLocaleString();
    },
    customLabel({ name }) {
      if (name) {
        return name;
      } else {
        return "Type Here";
      }
    },
    actionSearch() {
      let payload = {
        from: moment(this.range.start).format("YYYY-MM-DD"),
        to: moment(this.range.end).format("YYYY-MM-DD"),
        page: this.currentPage,
        limit: this.perPage,
      };
      this.isLoading = true;
      try {
        this.fetchReportBuffets(payload).then((response)=>{
          let res = response.data.data;
          this.buffets = res;
          let t = res.map((e) => {
            if (e.currency_code == "IDR") {
                return  parseInt(e.amount);
              }
              return 0;
          });
          let pts = res.map((e) => {
            if (e.currency_code == "PTS") {
              return parseInt(e.amount);
            }
            return 0;
          });
          let td = res.map((e) => {
            if (e.currency_code == "USD") {
              return parseFloat(e.amount);
            }
            return 0;
          });
          let total = res.map((e) => parseInt(e.count));
          
          this.totalRows = res.length
          this.total = total.reduce((a, b) => a + b);
          this.totalAmount = t.reduce((a, b) => a + b);
          this.totalAmountD = td.reduce((a, b) => a + b);
          this.totalAmountPTS = pts.reduce((a, b) => a + b);
          this.isLoading = false;
        });
      } catch (error) {
        this.isError = true;
        console.log(error);
        this.isLoading = false;
      }
    },
    formatPriceBasic(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    actionCreateTablePDF() {
      let payload = {
        from: this.range.start,
        to: this.range.end
      };
      let data = this.setBodyData()
      let footer = this.setFooter()
      let datatable = data.concat(footer)
      let report = new PDFReports(``, payload,this.curs,`${moment(payload.from).unix()}`,'-')
      
      report.SetHead(this.setTitleHead())
      report.SetData(datatable)
      report.BuildData()
      window.location.reload()
    },
    setBodyData() {
      let datatable = [];
      let n = 0;
      
      this.buffets.forEach((e) => {
        let amount_idr = this.formatPrice(e.amount, e.currency_code);
        let amount_usd = this.formatPrice(e.amount, e.currency_code);
        let amount_pts = this.formatPrice(e.amount, e.currency_code);
        let datain =[
          {content: ++n},
          {content:e.name},
          {content:e.count,styles: { halign: 'center' }},
          {content:e.currency_code=="IDR" ? amount_idr:0,styles: { halign: 'right' }},
          {content:e.currency_code=="USD" ? amount_usd:0,styles: { halign: 'right' }},
          {content:e.currency_code=="PTS" ? amount_pts:0,styles: { halign: 'right' }},
        ]
        datatable.push(datain)
      });
      return datatable;
    },
    setFooter(){

    },
    setTitleHead(){
      let head =["No","Name","Count","Price IDR","Price USD","Price Point"]
      return head
    },
    actionDownloadRecap() {
      const wb = xlsx.utils.book_new();
      let payload = {
        from: moment(this.range.start).format("YYYY-MM-DD"),
        to: moment(this.range.end).format("YYYY-MM-DD"),
      };
      wb.Props = {
        Title: "Report Buffet",
        Subject: "Report",
        Author: "Digital GAM",
        CreatedDate: new Date(),
      };
      let parseData = [];
      let data = this.buffets;
      if (data.length < 1) {
        this.$notify({
          group: "foo",
          type: "error",
          title: "Opss .. ",
          text: "Tidak ada data yang di rekap",
        });
      } else {
        let Attributes = [
          "No",
          "Offer Name",
          "Count",
          "Price IDR",
          "Price USD",
          "Price POINT",
        ];
        let wscols = [
          { wch: 8 },
          { wch: 35 },
          { wch: 10 },
          { wch: 30 },
          { wch: 30 },
          { wch: 30 },
          { wch: 30 },
        ];
        parseData.push(Attributes);
        let n = 0;
        data.forEach((e) => {
          let dataPeserta = [
            ++n,
            e.offer_name,
            e.count,
            e.currency_code == "IDR" ? e.amount : 0,
            e.currency_code == "USD" ? e.amount : 0,
            e.currency_code == "PTS" ? e.amount : 0,
          ];
          parseData.push(dataPeserta);
          console.log(dataPeserta);
        });
        // parseData.push("-", "TOTAL", "", total);
        const ws = xlsx.utils.aoa_to_sheet(parseData);
        ws["!cols"] = wscols;
        let name = "BUFFET-" + payload.from + "-to-" + payload.to;
        xlsx.utils.book_append_sheet(wb, ws, "REPORT");
        xlsx.writeFile(wb, "REPORT_" + name + ".xlsx");
      }
    },
  },
};
</script>
<style scoped>
.books {
  font-family:"Avenir Next" ,"effra", Helvetica, Arial, sans-serif;
}
</style>
