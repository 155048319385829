"use strict";
import jsPDF from "jspdf";
import "jspdf-autotable";
const moment = require('moment')
var doc = new jsPDF({
  orientation: "landscape",
  encryption: {
    userPassword: "rahasia",
    ownerPassword: "rahasia",
    userPermissions: ["print", "modify", "copy", "annot-forms"]
  }
});


class PDFReport {
  constructor(title,period,curs,reportname,publisher){
    this.title = title
    this.period = period
    this.curs = curs
    this.head = []
    this.data = []
    this.reportname = reportname
    this.publisher = publisher
  }
  SetHead(head){
    this.head.push(head)
  }
  SetData(data){
    this.data = data
  }
  BuildData(){
    console.log(this.data)
    doc.close()
    var totalPagesExp = "{total_pages_count_string}";
    doc.setFontSize(18);
    doc.setTextColor(100);
    doc.setFontSize(14)
    doc.text(`Sales Report ${this.title}`,150, 14, null, null, "center") // left , height
    doc.setFontSize(11)
    doc.text(`Publisher : ${this.publisher}`,14,25)
    doc.text(`Period  ${moment(this.period.from).format('DD MMMM YYYY')} s/d ${moment(this.period.to).format('DD MMMM YYYY')} In Curs ${this.curs}`, 14, 35);
    doc.autoTable({
      theme: "grid",
      head:this.head,
      body: this.data,
      startY: 45,
      showHead: "firstPage",
      rowPageBreak: "auto",
      styles: { cellPadding: 1.5, fontSize: 9 },
      didDrawPage: function(data) {
        var str = "Page " + doc.internal.getNumberOfPages();
        if (typeof doc.putTotalPages === "function") {
          str = str + " of " + totalPagesExp;
        }
        doc.setFontSize(10);
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        doc.text(str, data.settings.margin.left, pageHeight - 10);
      }
    });
  
    if (typeof doc.putTotalPages === "function") {
      doc.putTotalPages(totalPagesExp);
    }
    // doc.autoPrint()
    doc.close()
    doc.setDocumentProperties('report')
    doc.save(`${this.reportname}.pdf`);
    doc.close()
    
  }
}

export default PDFReport;